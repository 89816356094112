<template>
  <iframe
    class="c-mauthor-frame"
    name="mAuthorFrame"
    ref="mAuthorFrame"
    :src="mauthorSrc"
  ></iframe>
</template>

<script>
import { computed, ref, onBeforeUnmount, onMounted } from '@vue/runtime-core'
import { useAuth } from '@/core'
import useActivity from '@/composables/useActivity'

export default {
  name: 'mAuthor',

  props: {
    data: Object,
    itemId: Object,
  },
  emits: ['elementCompleted'],
  setup(props, { emit }) {
    let tempState = null
    const { token } = useAuth()
    const mAuthorFrame = ref(null)
    const passed = ref(false)
    const currScore = ref(null)
    const allPagesVisited = ref(null)
    const mauthorSrc = computed(() => {
      return `${process.env.VUE_APP_MAUTHOR_URL}?data=${props.data.content.embedId}&gateway=${process.env.VUE_APP_COSS_URL}&token=${token.value}`
    })
    const { saveElementState, getElementState } = useActivity()

    onMounted(() => {
      window.addEventListener('message', handleMessage)
      window.addEventListener('beforeunload', saveStateOnExit)
    })

    onBeforeUnmount(async () => {
      window.removeEventListener('message', handleMessage)
      window.removeEventListener('beforeunload', saveStateOnExit)
      await saveElementState(props.itemId, tempState)
    })

    function handleMessage(event) {
      const { eventType, eventData } = destructureMessage(event)

      switch (eventType) {
        case 'RESIZE':
          resizeFrame(eventData)
          break
        case 'PAGE_LOADED':
        case 'PAGE_RESULT':
          console.debug(eventData)
          break
        case 'ATTEMPT_COMPLETE':
          saveTempState(eventData)
          break
        case 'PLAYER_READY':
          restoreLessonState()
          break
        default:
          break
      }
    }

    async function restoreLessonState() {
      tempState = await getElementState(props.itemId)
      sendMessageToFrame(
        `LESSON_STATE:${JSON.stringify(tempState ? tempState : null)}`,
      )
    }

    function saveTempState(stateString) {
      const state = JSON.parse(stateString)
      passed.value = state.passed
      currScore.value = state.attemptScore.scaled
      allPagesVisited.value = state.allPagesVisited

      if (state.allPagesVisited && state.passed && state.checked) {
        emit('elementCompleted')
      }

      tempState = state
    }

    function resizeFrame(eventData) {
      const [, height] = eventData.split(';')
      mAuthorFrame.value.style.height = `${height}px`
    }

    function destructureMessage(event) {
      const eventTypeMarker =
        event.data.indexOf(':') > -1
          ? event.data.indexOf(':')
          : event.data.length

      const eventType = event.data.substr(0, eventTypeMarker)
      const eventData = event.data.substr(eventTypeMarker + 1)

      return {
        eventType,
        eventData,
      }
    }

    function sendMessageToFrame(message) {
      window.frames['mAuthorFrame'].postMessage(
        message,
        `${process.env.VUE_APP_MAUTHOR_URL}`,
      )
    }

    function saveStateOnExit() {
      saveElementState(props.itemId, tempState)
    }

    return {
      mauthorSrc,
      mAuthorFrame,
      currScore,
      allPagesVisited,
      passed,
    }
  },
}
</script>
