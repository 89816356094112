<template>
  <div class="c-video">
    <div class="c-video__wrapper">
      <TheHeading v-if="title" class="c-video__title">{{ title }}</TheHeading>
      <div v-if="video" class="c-video__wrapper">
        <VVideoPlayer
          ref="thePlayer"
          :video-url="video.url"
          :video-type="video.mimetype"
          :text-tracks="textTracks"
          :video-attributes="videoAttributes"
          :options="options"
          :chapters="chapters"
          @timeupdate="onTimeUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onBeforeMount, computed } from 'vue'
import VVideoPlayer from '@forlagshuset/v-video-player-vjs'
import { useAssets } from '@/core'
import TheHeading from '@/components/TheHeading'
import useCurtain from '@/composables/useCurtain'

export default {
  name: 'Video',

  components: {
    VVideoPlayer,
    TheHeading,
  },

  props: {
    data: Object,
  },
  emits: ['elementCompleted'],
  setup(props, { emit }) {
    const { fetchAsset, getAssetById } = useAssets()
    const { isCurtainOpen } = useCurtain()
    const title = computed(
      () => props.data && props.data.content && props.data.content.name,
    )
    const thePlayer = ref(null)
    const video = ref(null)
    const currentTime = ref(0)
    const textTracks = ref(null)
    const videoAttributes = ref({
      crossorigin: '',
      playsinline: '',
      controls: '',
    })
    const options = {
      playbackRates: [0.5, 1, 1.5, 2],
      language: 'nb',
      skipTimeForward: 15,
      skipTimeBackward: 15,
    }
    let autopause = false

    onBeforeMount(async () => {
      await fetchAsset(props.data.content.videoId)
      if (props.data.content.textTracks.length) {
        await Promise.all(
          props.data.content.textTracks.map(async tt => {
            await fetchAsset(tt.textTrackId)
          }),
        )
      }

      textTracks.value = props.data.content.textTracks.map(tt => {
        tt.src = getAssetById(tt.textTrackId).url

        return tt
      })
      video.value = getAssetById(props.data.content.videoId)
    })

    watch(video, val => {
      if (val.cover) {
        videoAttributes.value.poster = val.cover
      }
    })

    watch(isCurtainOpen, val => {
      if (!thePlayer.value) return
      if (val) {
        if (!thePlayer.value.player.paused()) {
          thePlayer.value.player.pause()
          autopause = true
        }
      } else {
        if (autopause) {
          thePlayer.value.player.play()
          autopause = false
        }
      }
    })

    const onTimeUpdate = event => {
      if (isNaN(event) || !thePlayer.value) return
      const videoDuration = thePlayer.value.player.duration()
      currentTime.value = thePlayer.value.player.currentTime()
      if (videoDuration && currentTime.value === videoDuration) {
        emit('elementCompleted')
      }
    }

    return {
      textTracks,
      video,
      options,
      currentTime,
      videoAttributes,
      onTimeUpdate,
      thePlayer,
      title,
    }
  },
}
</script>
