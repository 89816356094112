<template>
  <div class="c-content-renderer">
    <div v-if="contentItems.length" class="c-content-renderer__wrapper">
      <div
        class="c-content-renderer__element"
        :class="[`c-content-renderer__element--${item.contentType}`]"
        v-for="item in contentItems"
        :key="item.id"
      >
        <component
          v-if="item.contentData"
          :is="mapComponent(item.contentType)"
          :itemId="item.id"
          :data="getLocalizedData(item)"
          @elementCompleted="onElementCompleted(item.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ElementText from '@/components/Elements/text'
import ElementMAuthor from '@/components/Elements/mauthor'
import ElementVideo from '@/components/Elements/video'
import { useI18n } from 'vue-i18n'
import { useContents } from '@/core'

export default {
  name: 'ContentRenderer',

  props: {
    contentItems: Array,
  },
  emits: ['contentCompleted'],
  components: {
    ElementText,
    ElementMAuthor,
    ElementVideo,
  },

  setup(props, { emit }) {
    const { locale } = useI18n()
    const { getObjectById } = useContents()
    const mapComponent = type => {
      const types = {
        video: 'ElementVideo',
        text: 'ElementText',
        mauthor: 'ElementMAuthor',
      }

      return types[type]
    }

    const onElementCompleted = itemId => {
      emit('contentCompleted', itemId)
    }

    const getLocalizedData = item => {
      return getObjectById(item.contentId, locale.value)
    }
    return {
      mapComponent,
      onElementCompleted,
      getLocalizedData,
    }
  },
}
</script>
